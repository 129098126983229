<template>
  <div class="pb-24">
    <div class="flex gap-4 items-center flex-wrap mb-10">
      <h2 class="text-2xl font-bold">All Templates</h2>
      <div
        class="text-sm text-gray-700 cursor-pointer"
        @click="$refs.createModal.open()"
      >
        + Add New Template
      </div>
    </div>

    <div class="mb-10">
      <search-form
        v-model="query.search"
        placeholder="Search subject"
        @submit="getTemplates"
      />
    </div>

    <div
      class="gap-5 gap-y-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mb-10"
    >
      <template v-if="templates.loading">
        <template v-for="index in 4">
          <skeleton-loader
            :key="index"
            class="opacity-10 rounded"
            height="10rem"
            width="100%"
          />
        </template>
      </template>
      <template v-else v-for="(template, index) in templates.data">
        <div
          :key="index"
          class="border card cursor-pointer flex flex-col gap-4 hover:shadow-card"
          @click="edit(template)"
        >
          <div class="font-bold text-gray-600 text-lg">
            {{ template.subject | truncate(40) }}
          </div>
          <div>
            {{ template.body | truncate(80) }}
          </div>
        </div>
      </template>
    </div>

    <Pagination
      v-model="query.page"
      :meta="templates.meta"
      :is-loading="templates.loading"
      @change="getTemplates"
    />

    <CreateCommunicationTemplate @update="getTemplates" ref="createModal" />
    <PreviewCommunicationTemplate
      ref="previewModal"
      :template="templates.selected"
      @update="getTemplates"
    />
  </div>
</template>

<script>
export default {
  name: 'AllCommunicationTemplates',
  components: {
    CreateCommunicationTemplate: () =>
      import(
        '@/components/forms/communications/templates/CreateCommunicationTemplate.vue'
      ),
    PreviewCommunicationTemplate: () =>
      import(
        '@/components/forms/communications/templates/PreviewCommunicationTemplate.vue'
      ),
    Pagination: () => import('@/components/presentation/Pagination.vue'),
  },
  data() {
    return {
      query: {
        search: '',
        page: 1,
      },
      templates: this.$options.resource([], { meta: {}, selected: null }),
    };
  },
  computed: {
    count() {
      return this.templates.meta.chunk_count || 1;
    },
    limit() {
      return this.templates.meta.limit || 1;
    },
    page() {
      return this.templates.meta.page || 1;
    },
    pages() {
      return this.templates.meta.page_count || 1;
    },
    total() {
      return this.templates.meta.total_count || 0;
    },
  },
  mounted() {
    this.getTemplates();
  },
  methods: {
    async edit(template) {
      this.templates.selected = template;
      await this.$nextTick();
      this.$refs.previewModal.open();
    },
    async getTemplates() {
      this.$refs.previewModal?.close();
      this.templates.data = [];
      this.templates.selected = null;
      this.templates.loading = true;
      await this.sendRequest(
        'admin.communications.templates.list',
        this.query,
        {
          success: (response) => {
            const { data, ...meta } = response.data.datatable;
            this.templates.data = data;
            this.templates.meta = meta;
          },
          error: (error) => {
            this.$error({ title: error?.response?.data?.message });
          },
        }
      ).finally(() => (this.templates.loading = false));
    },
  },
};
</script>
